import { RefObject, useEffect } from 'react';
import useEventListener from '@activebrands/core-web/hooks/useEventListener';

interface ValidRefTarget {
    contains(target: EventTarget | null): any;
}

const useOnClickOutside = (ref: RefObject<ValidRefTarget>, handler: Function) => {
    const listener = useEventListener('window');

    useEffect(() => {
        const callback = (event: Event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref?.current || ref?.current.contains(event?.target)) {
                return;
            }

            handler(event);
        };

        const mousedown = listener.subscribe('mousedown', callback);
        const touchstart = listener.subscribe('touchstart', callback);

        return () => {
            listener.unsubscribe('mousedown', mousedown);
            listener.unsubscribe('touchstart', touchstart);
        };
        // Reload only if ref or handler changes
    }, [ref, handler]);
};

export default useOnClickOutside;
