import { CountryCode } from 'libphonenumber-js';

/**
 * Parses Country Code before we use the getCountryCallingCode function
 * Makes sure that the country code is in the correct format
 * @param countryCode
 * @example
 * countryCode = 'no', 'NO', 'eu', 'EU', null
 * @returns 'NO', 'AT'
 */

// @todo: add types
const parseUserCountryForGetCountryCallingCode = (countryCode: string, fallbackCountryCode: any): CountryCode => {
    const countryCodeFallback: any = countryCode ? countryCode.toUpperCase() : fallbackCountryCode;
    let parsedCountryCode: any = countryCodeFallback;

    if (countryCodeFallback === 'EU') {
        parsedCountryCode = 'AT';
    }

    return parsedCountryCode;
};

export default parseUserCountryForGetCountryCallingCode;
