import PropTypes from 'prop-types';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import InputErrors from 'components/Form/InputErrors';

const StyledTextArea = styled('textarea', {
    width: '100%',
    padding: '16px 0 0 16px',
    color: 'var(--color-text)',
    border: `1px solid var(--color-border)`,
    borderRadius: 0,
    resize: 'none',
    appearance: 'none',

    '::placeholder': {
        color: 'var(--color-text-subtle)',
    },
});

const TextArea = ({ text, name, errors, rows, label, $style, ...rest }) => {
    const [css] = useStyletron();

    return (
        <div className={css({ marginTop: '12px', ...$style })}>
            {label && <label htmlFor={name}>{label}</label>}
            <StyledTextArea id={name} name={name} rows={rows} {...rest}>
                {text}
            </StyledTextArea>
            {errors.length > 0 && <InputErrors errors={errors} />}
        </div>
    );
};

TextArea.propTypes = {
    $style: PropTypes.object,
    errors: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.number,
    text: PropTypes.string,
};

TextArea.defaultProps = {
    $style: {},
    errors: [],
    label: undefined,
    name: undefined,
    rows: 3,
    text: undefined,
};

export default TextArea;
