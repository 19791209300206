import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useForm from '@activebrands/core-web/hooks/useForm';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { signUp } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import InputErrors from 'components/Form/InputErrors';
import ThemeButton from 'components/buttons/ThemeButton';
import LoadingIcon from 'components/icons/LoadingIcon';
import StateIcon from 'components/icons/StateIcon';
import Input from 'components/inputs/Input';
import Paragraph from 'components/text/Paragraph';
import ConfirmationMessage from './ConfirmationMessage';

// name, label, type, ...rest
const fields = [
    ['firstname', fm('First name')],
    ['lastname', fm('Last name')],
    ['address', fm('Address')],
    ['city', fm('City')],
    ['postalcode', fm('Zip code')],
    ['country', fm('Country')],
    ['phone', fm('Phone')],
];

const SignUpFormStepTwo = ({ setForm, userEmail }) => {
    const dispatch = useTypedDispatch();
    const [css] = useStyletron();

    const [{ isLoading, isSubmitted, email, errors }, setState] = useState({
        isLoading: false,
        isSubmitted: false,
        email: undefined,
        errors: [],
    });

    const form = useForm(async field => {
        const { firstname, lastname, address, city, postalcode, country, phone } = field;

        setState(prevState => ({ ...prevState, isLoading: true }));

        const response = await dispatch(
            signUp({
                address,
                city,
                country,
                email: userEmail.email,
                firstname,
                isRegistration: false,
                lastname,
                optOut: false,
                phone,
                postalcode,
            })
        );

        if (response.status === 200) {
            setState(prev => ({ ...prev, isSubmitted: true, email, errors: [], isLoading: false }));
        } else {
            setState(prev => ({ ...prev, errors: [response.error], isLoading: false }));
        }
    });

    const handleChange = event => {
        form.errors[event.target.name] ? form.validateInput(event) : undefined;
    };

    return isSubmitted ? (
        <ConfirmationMessage userEmail={userEmail} onClick={() => setForm('sign-in')} />
    ) : (
        <>
            {/* 
            //TODO: check with design, go back in this step is not needed
            <ThemeButton
                $style={{ width: '100%', marginTop: '24px', marginBottom: '16px' }}
                disabled={isLoading}
                size="md"
                theme={{ type: 'solid', color: 'primary' }}
                type="submit"
                onClick={() => setForm('sign-up-step-one')}
            >
                {fm('Go back')}
            </ThemeButton> */}

            <div
                className={css({
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '12px',
                    marginTop: '24px',
                })}
            >
                <Paragraph $style={{ marginBottom: '16px' }} fontKeys="Regular-16/100">
                    {fm('Sign up')}
                </Paragraph>
                <Paragraph $style={{ marginBottom: '16px' }} fontKeys="Regular-16/100">
                    {`${fm('Step')} 2/2`}
                </Paragraph>
            </div>
            <form {...form.props} id="sign-up-step-two" className={css({ marginTop: '32px' })}>
                {fields.map(([name, label, type, rest]) => (
                    <Input
                        required
                        $style={{ marginTop: '24px' }}
                        errors={form.errors[name]}
                        key={name}
                        label={label}
                        maxLength="150"
                        name={name}
                        placeholder={label}
                        type={type}
                        {...rest}
                        onChange={handleChange}
                    />
                ))}
                {errors.length > 0 && <InputErrors errors={errors} />}
                {/*TODO: If not keep for future use, delete*/}
                {/* <Checkbox
                    required
                    $style={{ marginTop: '24px', textAlign: 'left' }}
                    errors={form.errors.confirm}
                    label={signupConsentText?.[0]?.richText}
                    name="confirm"
                    onChange={form.validateInput}
                /> */}
                <ThemeButton
                    $style={{
                        width: '100%',
                        marginTop: '24px',
                        marginBottom: '16px',
                    }}
                    disabled={isLoading}
                    size="md"
                    theme="inverted"
                    type="submit"
                >
                    {isLoading ? (
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                            })}
                        >
                            <LoadingIcon />
                        </div>
                    ) : (
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            })}
                        >
                            <Paragraph fontKeys="Miscellaneous/24_100_-2">{fm('Complete profile')}</Paragraph>
                            <StateIcon size="16px" state="arrow-right" />
                        </div>
                    )}
                </ThemeButton>
                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'center',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    })}
                    onClick={() => setState(prev => ({ ...prev, isSubmitted: true }))}
                >
                    {fm('Skip for now')}
                </div>
            </form>
        </>
    );
};

SignUpFormStepTwo.propTypes = {
    setForm: PropTypes.func,
    userEmail: PropTypes.object,
};

export default SignUpFormStepTwo;
