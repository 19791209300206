import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useAccountQuery from 'queries/useAccountQuery';
import Link from '@activebrands/core-web/components/Link';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled } from '@activebrands/core-web/libs/styletron';
import { resetPassword } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import Form from 'components/Form';
import OverlayContent from 'components/Header/OverlayContent';
import ConfirmationMessage from './ConfirmationMessage';

const StyledLink = styled(Link, {
    textDecoration: 'underline',
    marginTop: '16px',
    marginLeft: 'auto',
});

const ResetPasswordForm = ({ setForm, forgottenPasswordText, infoText }) => {
    const dispatch = useTypedDispatch();

    const { forgotPasswordContent, forgotPasswordConfirmationContent } = useAccountQuery();

    const [requestEmail, setRequestEmail] = useState(undefined);

    const [state, setState] = useState({
        email: undefined,
        errors: [],
        isLoading: false,
        isSubmitted: false,
    });

    const { isLoading, isSubmitted } = state;

    const onSubmit = async ({ email }) => {
        setState(prevState => ({ ...prevState, isLoading: true }));

        const response = await dispatch(resetPassword(email));

        if (response.message === 'Success') {
            setRequestEmail(email);
            setState(prevState => ({ ...prevState, email, isLoading: false, isSubmitted: true, errors: [] }));
        } else {
            setState(prevState => ({ ...prevState, errors: [response?.error], isLoading: false }));
        }
    };

    return isSubmitted ? (
        <ConfirmationMessage
            content={forgotPasswordConfirmationContent}
            userEmail={requestEmail}
            buttonText={fm('Back to sign in')}
            heading={fm('One step left..')}
            onClick={() => setForm('sign-in')}
        />
    ) : (
        <div>
            <OverlayContent content={forgotPasswordContent} />
            <Form
                $style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    paddingTop: '12px',
                }}
                buttonLabel={isLoading ? `${fm('Loading')}...` : fm('Reset password')}
                buttonProps={{
                    disabled: isLoading,
                    $style: {
                        flexBasis: '100%',
                        marginTop: '24px',
                        marginBottom: '16px',
                    },
                }}
                errors={state.errors}
                fields={[
                    {
                        label: fm('Email'),
                        name: 'email',
                        required: true,
                        type: 'email',
                        $style: {
                            flexBasis: '100%',
                        },
                    },
                    {
                        type: 'render',
                        render: () => (
                            <StyledLink isVolatile role="button" tabIndex="0" onClick={() => setForm('sign-in')}>
                                {fm('Back to sign in')}
                            </StyledLink>
                        ),
                    },
                ]}
                onSubmit={onSubmit}
            />
            {forgottenPasswordText && <RichText data={forgottenPasswordText} tagComponents={{ paragraph: infoText }} />}
        </div>
    );
};

ResetPasswordForm.propTypes = {
    forgottenPasswordText: PropTypes.string,
    infoText: PropTypes.string,
    setForm: PropTypes.func,
};

export default ResetPasswordForm;
