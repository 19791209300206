import fm from 'format-message';
import PropTypes from 'prop-types';
import stringReplace from '@grebban/utils/string/replaceSubStrings';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import OverlayContent from 'components/Header/OverlayContent';
import ThemeButton from 'components/buttons/ThemeButton';

const ConfirmationMessage = ({ userEmail, $style = {}, onClick = () => null, content = [] }) => {
    const [css] = useStyletron();
    const replaceEmail = {
        '{email}': userEmail,
    };

    // Replace {email} with user email
    content.forEach(obj => {
        if (obj.component === 'paragraph') {
            obj.text = stringReplace(obj.text, replaceEmail);
        }
    });

    return (
        <div className={css($style)}>
            <OverlayContent content={content} />
            <ThemeButton $style={{ width: '100%', marginTop: '24px', marginBottom: '16px' }} onClick={onClick}>
                {fm('Back to sign in')}
            </ThemeButton>
        </div>
    );
};

ConfirmationMessage.propTypes = {
    $style: PropTypes.object,
    content: PropTypes.array,
    onClick: PropTypes.func,
    userEmail: PropTypes.object,
};

export default ConfirmationMessage;
