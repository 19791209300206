import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { styled } from '@activebrands/core-web/libs/styletron';
import { resetPassword } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import Form from 'components/Form';
import Heading from 'components/text/Heading';
import ConfirmationMessage from './ConfirmationMessage';

const StyledLink = styled(Link, {
    textDecoration: 'underline',
    marginTop: '16px',
    marginLeft: 'auto',
});

const passwordFields = [
    {
        name: 'newPassword',
        type: 'password',
        label: fm('New password'),
        required: true,
    },
    {
        name: 'confirmPassword',
        type: 'password',
        label: fm('Confirm password'),
        required: true,
    },
];

const UpdatePasswordForm = ({ setForm, id, hash }) => {
    const [state, setState] = useState({
        errors: [],
        isLoading: false,
        isSubmitted: false,
    });

    const { isLoading, isSubmitted, errors } = state;
    const dispatch = useTypedDispatch();

    const handleSubmit = async inputs => {
        setState(prevState => ({ ...prevState, errors: [], isLoading: true }));

        if (inputs.newPassword === inputs.confirmPassword) {
            const response = await dispatch(resetPassword(inputs.newPassword, id, hash));
            if (response.status === 200) {
                setState({ isSubmitted: true, errors: [], isLoading: false });
            }

            if (response.error) {
                setState(prevState => ({
                    ...prevState,
                    errors: [
                        { message: fm('Could not update password. Contact customer service if the problem persists.') },
                    ],
                    isLoading: false,
                }));
            }
        } else {
            setState(prevState => ({
                ...prevState,
                errors: ['passwordMismatch'],
                isLoading: false,
            }));
        }
    };

    return isSubmitted ? (
        <ConfirmationMessage
            buttonText={fm('Back to sign in')}
            heading={fm('Your password has been updated')}
            onClick={() => setForm('sign-in')}
        />
    ) : (
        <>
            <Heading $style={{ marginTop: '64px' }}>{fm('Update password')}</Heading>
            <Form
                id="update-password"
                $style={{ marginTop: '32px', paddingTop: '12px' }}
                buttonLabel={fm('Update password')}
                buttonProps={{
                    disabled: isLoading,
                    $style: {
                        marginTop: '24px',
                    },
                }}
                errors={errors}
                fields={[
                    ...passwordFields,
                    {
                        type: 'render',
                        render: () => (
                            <StyledLink isVolatile role="button" tabIndex="0" onClick={() => setForm('sign-in')}>
                                {fm('Back to sign in')}
                            </StyledLink>
                        ),
                    },
                ]}
                onSubmit={handleSubmit}
            />
        </>
    );
};

UpdatePasswordForm.propTypes = {
    hash: PropTypes.string,
    id: PropTypes.string,
    setForm: PropTypes.func,
};

export default UpdatePasswordForm;
