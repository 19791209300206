import { Fragment } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useForm from '@activebrands/core-web/hooks/useForm';
import { styled } from '@activebrands/core-web/libs/styletron';
import InputErrors from 'components/Form/InputErrors';
import StateButton from 'components/buttons/StateButton';
import Checkbox from 'components/inputs/CheckboxInput';
import DateInput from 'components/inputs/DateInput';
import Input from 'components/inputs/Input';
import PhoneInput from 'components/inputs/PhoneInput';
import Select from 'components/inputs/Select';
import TextArea from 'components/inputs/TextArea';

const StyledForm = styled('form', {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    gap: '12px 0',
});

const Form = ({
    buttonLabel = fm('Submit'),
    buttonProps = {},
    errors = [],
    fields,
    loading = false,
    onSubmit,
    ...rest
}) => {
    // Pass in true to get a different serialization format.
    const { props, errors: formErrors, validateInput } = useForm(onSubmit);

    const defaultLayoutStyles = {
        width: '100%',
    };

    return (
        <StyledForm {...props} {...rest}>
            {fields.map((field, i) => {
                const { $style, name, options, render, type } = field;

                const sortedOptions =
                    type === 'select' && options
                        ? [...options].sort((a, b) => a.label.localeCompare(b.label))
                        : options;

                const fieldProps = {
                    ...field,
                    $style: { ...defaultLayoutStyles, ...$style },
                    key: `${i}_${name}`,
                    errors: formErrors[name],
                    onBlur: e => validateInput(e),
                    onChange: e => {
                        // If there were errors for this field, validate this field to see if they are gone.
                        if (formErrors[e.target.name]) {
                            validateInput(e);
                        }
                        // Run the custom handleChange function if it exists, allows an interface to pass
                        // custom functions to run on certain fields.
                        if (field.$handleChange) {
                            field.$handleChange(e);
                        }
                    },
                };

                switch (type) {
                    case 'select':
                        return sortedOptions && sortedOptions.length > 0 ? (
                            <Select {...fieldProps} options={sortedOptions} />
                        ) : null;
                    case 'checkbox':
                        return <Checkbox {...fieldProps} />;
                    case 'textarea':
                        return <TextArea {...fieldProps} />;
                    case 'tel':
                        return <PhoneInput {...fieldProps} />;
                    case 'render':
                        return (
                            <Fragment key={`${i}_render`}>
                                {render({
                                    ...fieldProps,
                                    $style: { ...defaultLayoutStyles, ...$style },
                                    type: undefined, // Will prevent type from being added to the DOM
                                })}
                            </Fragment>
                        );
                    case 'date':
                        return <DateInput {...fieldProps} />;
                    default:
                        return (
                            <Input
                                {...fieldProps}
                                labelStyle={{
                                    color: 'var(--color-text-subtle)',
                                    ':after': { color: 'var(--black)' },
                                }}
                            />
                        );
                }
            })}
            {errors.length > 0 && <InputErrors errors={errors} />}
            <StateButton
                $style={buttonProps.style}
                iconPosition={buttonProps.iconPosition || 'right'}
                size={buttonProps.size || 'md'}
                state={loading ? 'loading' : 'arrow'}
                theme={buttonProps.theme || 'inverted'}
                type="submit"
                {...buttonProps}
            >
                {buttonLabel}
            </StateButton>
        </StyledForm>
    );
};

const textInputProp = PropTypes.shape({
    name: PropTypes.string,
    inputStyles: PropTypes.object,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.oneOf(['hidden', 'text', 'tel', 'email', 'number', 'password']).isRequired,
});

const checkboxProp = PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
    name: PropTypes.string,
    required: PropTypes.bool,
});

const textAreaProp = PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.number,
    text: PropTypes.string,
    required: PropTypes.bool,
});

const selectProp = PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.exact({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ).isRequired,
    required: PropTypes.bool,
});

const renderProp = PropTypes.shape({
    render: PropTypes.func.isRequired,
});

Form.propTypes = {
    buttonLabel: PropTypes.string,
    buttonProps: PropTypes.object,
    errors: PropTypes.array,
    fields: PropTypes.arrayOf(PropTypes.oneOfType([textInputProp, textAreaProp, selectProp, checkboxProp, renderProp]))
        .isRequired,
    isCheckoutForm: PropTypes.bool,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    proceedToCheckoutStep: PropTypes.func,
    separateBillingAndShippingAddress: PropTypes.bool,
    seperateShippingCheckbox: PropTypes.object,
};

export default Form;
